<template>
  <div class='pb-8 px-16'>
    <div class='flex flex-row my-6'>
      <h1 class='text-3xl font-semibold tracking-wide mr-2'>거래내역</h1>
    </div>
    <div class='flex flex-row my-6'>
      <el-date-picker
        v-model='date'
        style='width: 20rem;'
        type='daterange'
        placeholder='거래일자'
        :default-value='date'
        format='yyyy-MM-dd'
        @change='getPeriodOrders'
        range-separator='~'
        start-placeholder='Start Date'
        end-placeholder='End Date'
        align='right'
        unlink-panels
        :picker-options='pickerOptions'>
      </el-date-picker>
      <input class='form-input ml-2' type='text' placeholder='회사코드/투자명칭' v-model='searchEntities' @keyup.enter='getPeriodOrders' />
      <input class='form-input ml-2' type='text' placeholder='종목코드/종목명' v-model='searchListing' @keyup.enter='getPeriodOrders' />
    </div>
    <div class='overflow-x-scroll  mt-5'>
      <table class=''>
        <thead>
          <tr class='font-semibold uppercase tracking-wide border-t border-b'>
            <th class='px-2 py-2 border-l border-r'>ID</th>
            <table-header-filter-text
              v-bind:filter-text.sync='filterCreatedOn'
              defaultLabel='기준일자'
              class='px-2 py-2 text-left border-r' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterFund'
              defaultLabel='펀드'
              class='px-2 py-2 text-left border-r' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterShareCode'
              defaultLabel='자산코드'
              class='px-2 py-2 text-left border-r' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterName'
              defaultLabel='자산명'
              class='px-2 py-2 text-left border-r' />
            <th class='px-2 py-2 border-r'>투자번호</th>
            <th class='px-2 py-2 border-r'>제한</th>
            <table-header-filter-text
              v-bind:filter-text.sync='filterTradeType'
              defaultLabel='매매구분'
              class='px-2 py-2 text-left border-r' />
            <th class='px-2 py-2 text-right border-r'>수량</th>
            <th class='px-2 py-2 text-right border-r'>단가</th>
            <th class='px-2 py-2 text-right border-r'>수수료</th>
            <th class='px-2 py-2 text-right border-r'>세금</th>
            <th class='px-2 py-2 text-right border-r'>금액</th>
            <th class='px-2 py-2 text-right border-r'>처리금액</th>
            <th class='px-2 py-2 text-right border-r'>현금배당</th>
            <th class='px-2 py-2 text-left border-r'>전략</th>
            <th class='px-2 py-2 text-left border-r'>결제일</th>
            <th class='px-2 py-2 text-left border-r'>거래방법</th>
            <th class='px-2 py-2 text-left border-r'>원금구분</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='order in filteredData'
            :key='`order-${order.id}`'
            class='uppercase tracking-wide border-t border-b'>
            <td class='px-2 py-2 text-left border-l border-r'>{{ order.id }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.created_on }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.short_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.name }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ order.investment_id }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.limit_order}}</td>
            <td class='px-2 py-2 text-left border-r'>{{ actionName(order)}}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.quantity) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.price) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.fee) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.tax) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.transaction_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.transaction_net_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ numberStyle(order.dividend) }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.strategy }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.settlement_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.trade_type }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ order.principal_type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import StringHelpers               from '@/utils/string-helpers'
import TableHeaderFilterText       from '@/components/TableHeaderFilterText.vue'
import OrdersApi                   from '@/api/v1/orders'
import dayjs from 'dayjs'

export default {
  name: 'InvestmentOrders',
  components: {
    TableHeaderFilterText,
  },
  data () {
    return {
      date: [dayjs(), dayjs()],
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      orders: [],
      searchEntities: '',
      searchListing: '',
      filterCreatedOn: '',
      filterFund: '',
      filterShareCode: '',
      filterName: '',
      filterTradeType: '',
    }
  },
  computed: {
    filteredData () {
      if (this.filterCreatedOn === '' &&
        this.filterFund === '' &&
        this.filterShareCode === '' &&
        this.filterName === '' &&
        this.filterTradeType === '') {
        return this.orders
      } else {
        return this.orders.filter(data =>
          (this.filterCreatedOn === ''    || StringHelpers.stringIncludesInsensitive(data.created_on, this.filterCreatedOn)) &&
          (this.filterFund === ''    || StringHelpers.stringIncludesInsensitive(data.fund_name, this.filterFund)) &&
          (this.filterShareCode === '' || StringHelpers.stringIncludesInsensitive(data.share_code, this.filterShareCode)) &&
          (this.filterName === ''   || StringHelpers.stringIncludesInsensitive(data.name, this.filterName)) &&
          (this.filterTradeType === ''   || StringHelpers.stringIncludesInsensitive(data.trade_type, this.filterTradeType))
        )
      }
    },
  },
  methods: {
    initialEntityName () {
      return this.useComponent ? '00000' : ''
    },
    getPeriodOrders () {
      OrdersApi.getPeriodOrders({
                  start_date: dayjs(this.date[0]).format('YYYYMMDD'),
                  end_date: dayjs(this.date[1]).format('YYYYMMDD'),
                  search_entities: this.searchEntities,
                  search_listing: this.searchListing
                }).then(resp => {
        this.orders = resp
      })
    },
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : 0
    },
    actionName (order) {
      return (order.action === 0 ? '매수' : '매도') + (order.is_cancel ? '취소' : '')
    },
  },
  mounted () {
    this.getPeriodOrders()
  },
}
</script>
